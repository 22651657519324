<template>
  <b-card border-variant="secondary" body-bg-variant="secondary">
         <div v-if="show_spinner == true">
      <Preloader /></div>
<div v-if="show_spinner == false">
    <div v-if="($route.params.enquirydetail == undefined)">
      <div v-if="this.$route.params.enquirydetail != 'DR-MSG' && show_spinner == true">
      <Preloader /></div>
    </div>
    <div v-if="$store.getters.currentEnquiry == 0 && count > 1">
      <!--      <b-button-->
      <!--        id="btn-back"-->
      <!--        variant="link"-->
      <!--        class="po-left"-->
      <!--        v-on:click="closeTab"-->
      <!--      >-->
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="$router.push({ name: 'home' })"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <div class="text-center">
        <b-img :src="path + enquiry_ico" alt="enquiry"></b-img>
        <p class="text-22 semi-bold mt-2 mb-0">
          {{ $t('enquiry_title') }}
          <br />
          <span v-if="show_description == true" style="font-weight: 400;">{{
            $t('enquiry_title_2')
          }}</span>
        </p>
      </div>
      <hr />
      <ul class="list-unstyled mt-4 mb-0">
        <li
          class="d-flex align-items-center mt-2 px-3 py-2 service"
          v-for="(enquiry, index) in $store.getters.enquiry.enquiries"
          :key="index"
          v-on:click="ButtonServiceEnquiry(index)"
        >
          <figure
            class="d-flex justify-content-center align-items-center mr-2 mb-0"
          >
            <b-img :src="path + btn_ico" alt="enter"></b-img>
          </figure>
          <div class="mr-2">
            <p class="text-22 semi-bold text-white mb-1">
              {{ enquiry.btntitle }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [$store.getters.enquiry.infoserviceprice])
              }}
            </p>
          </div>
          <b-img
            class="position-right"
            :src="path + angle_right"
            alt="enter"
          ></b-img>
        </li>
      </ul>
    </div>
    <div v-if="$store.getters.currentEnquiry == 1">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="
          count == 1
            ? closeTab()
            : $route.params.enquirydetail != undefined && $route.params.enquirydetail == 'DR-MSG'
            ? $router.push({ name: 'home' })
            : ButtonReturnToServices()
        "
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <div class="text-center">
        <b-img :src="path + enquiry_ico" alt="enquiry"></b-img>
        <p class="text-22 semi-bold mt-2 mb-0">
          {{ title }}
        </p>
        <div v-if="texts.length">
          <b-link v-b-toggle.collapse-text>
            Právne a prevádzkové pokyny čítajte ... TU</b-link
          >
          <b-collapse id="collapse-text" class="mt-2">
            <p
              v-for="(text, index) in texts"
              :key="index"
              class="text-14"
              :style="{
                color: '#' + text.color,
                textAlign: 'justify',
              }"
            >
              {{ text.content }}
            </p>
          </b-collapse>
        </div>
      </div>
      <hr />
      <p
        class="text-14 semi-bold mb-0"
        :class="{
          submiterror:
            (!$v.name.required || !$v.name.minLength || !$v.name.maxLength) &&
            error,
        }"
      >
        {{ $t('name_and_surname') }}
        <span class="text-14 text-muted normal"
          >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
        >
      </p>
      <b-form-input
        id="name"
        type="text"
        v-model.trim.lazy="$v.name.$model"
      ></b-form-input>
      <p class="text-14 text-danger error" v-if="!$v.name.required && error">
        {{ $t('must_be_filled') }}
      </p>
      <p class="text-14 text-danger error" v-if="!$v.name.minLength && error">
        {{ $t('put_min') }}&nbsp;{{ $v.name.$params.minLength.min }}&nbsp;{{
          $t('char')
        }}
      </p>
      <p class="text-14 text-danger error" v-if="!$v.name.maxLength && error">
        {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
          $t('chars')
        }}
      </p>
      <p
        class="text-14 semi-bold mb-0 mt-3"
        :class="{
          submiterror:
            (!$v.inr.required || !$v.inr.minLength || !$v.inr.maxLength) &&
            error,
        }"
      >
        {{ $t('your_in') }}
        <span class="text-14 text-muted normal"
          >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
        >
      </p>
      <b-form-input
        id="inr"
        type="text"
        v-model.trim.lazy="$v.inr.$model"
      ></b-form-input>
      <p class="text-14 text-danger error" v-if="!$v.inr.maxLength && error">
        {{ $t('put_max') }}&nbsp;{{ $v.inr.$params.maxLength.max }}&nbsp;{{
          $t('chars')
        }}
      </p>
      <p v-if="inrMinLength && error" class="text-14 text-danger error">
        {{ $t('put_min') }}&nbsp;9&nbsp;{{ $t('numbers') }}
      </p>
      <p v-if="inrMaxLength && error" class="text-14 text-danger error">
        {{ $t('put_max') }}&nbsp;12&nbsp;{{ $t('numbers') }}
      </p>
      <div v-if="show_spinner == true">
      <Preloader />
    </div>
      <div v-for="question in questions" :key="question.id">
        <div
          class="mt-3"
          v-if="question.type !== 'GROUP' && question.type !== 'MULTISELECT'"
        >
          <p
            v-if="question.question && question.parent == null"
            class="text-14 semi-bold mb-0"
          >
            {{ question.question }}
          </p>
          <p
            v-if="question.comment && question.parent == null"
            class="text-14 text-gray normal mb-0"
          >
            {{ question.comment }}
          </p>
          <figure v-if="question.image && question.parent == null">
            <img
              class="question-image"
              :src="question.image"
              :alt="qustion.id"
            />
          </figure>
        </div>
        <b-form-input
          v-if="question.type == 'STRING' && question.default == 'NOW'"
          :placeholder="new Date() | moment"
          readonly
        >
        </b-form-input>
        <b-form-input
          v-if="
            question.parent == null &&
              question.type == 'STRING' &&
              question.default != 'NOW'
          "
          v-model="answers[question.id]"
          type="text"
        ></b-form-input>
        <b-form-textarea
          v-if="question.parent == null && question.type == 'TEXT'"
          :value="question.default"
          v-model="answers[question.id]"
        ></b-form-textarea>
        <b-form-select
          v-if="question.parent == null && question.type == 'SELECT'"
          :id="question.id"
          v-model="answers[question.id]"
          :options="question.options"
          :style="selectStyle"
        >
          <template v-slot:first>
            <option disabled>Zvoľte ak sa Vás to týka</option>
          </template>
        </b-form-select>
        <multiselect
          v-if="question.parent == null && question.type == 'MULTISELECT'"
          v-model="answers[question.id]"
          :options="question.options"
          :multiple="true"
          :searchable="false"
          :hide-selected="true"
          placeholder="Zvoľte ak sa Vás to týka"
          selectLabel=""
        ></multiselect>
        <div
          v-if="question.type == 'GROUP'"
          class="wrapper-collapse mt-4"
          :style="selectStyle"
        >
          <b-link
            v-b-toggle="question.id"
            class="text-14 text-dark semi-bold d-block"
            @click="collapse"
            >Sekcia:
            <span class="text-15">{{ question.question }}</span></b-link
          >
          <b-collapse :id="question.id">
            <div v-for="item in questions" :key="item.id">
              <div class="mt-3" v-if="item.parent == question.id">
                <p
                  v-if="item.question && item.parent == question.id"
                  class="text-14 semi-bold mb-0"
                >
                  {{ item.question }}
                </p>
                <p
                  v-if="item.comment && item.parent == question.id"
                  class="text-14 text-gray normal mb-0"
                >
                  {{ item.comment }}
                </p>
                <figure v-if="item.image && item.parent == question.id">
                  <img
                    class="question-image"
                    :src="item.image"
                    :alt="item.id"
                  />
                </figure>
              </div>
              <multiselect
                v-if="item.parent == question.id && item.type == 'MULTISELECT'"
                v-model="answers[item.id]"
                :options="item.options"
                :multiple="true"
                :searchable="false"
                :hide-selected="true"
                placeholder="Zvoľte ak sa Vás to týka"
                selectLabel=""
              ></multiselect>
              <b-form-select
                v-if="item.parent == question.id && item.type == 'SELECT'"
                :id="item.id"
                v-model="answers[item.id]"
                :options="item.options"
                :style="selectStyle"
              >
                <template v-slot:first>
                  <option disabled>Zvoľte ak sa Vás to týka</option>
                </template>
              </b-form-select>
              <b-form-input
                v-if="
                  item.parent == question.id &&
                    item.type == 'STRING' &&
                    item.default != 'NOW'
                "
                v-model="answers[item.id]"
                type="text"
              ></b-form-input>
              <b-form-textarea
                v-if="item.parent == question.id && item.type == 'TEXT'"
                :value="item.default"
                v-model="answers[item.id]"
              ></b-form-textarea>
            </div>
          </b-collapse>
        </div>
      </div>
      <b-button block size="lg" class="mt-4" v-on:click="serviceEnquiry(2)"
        >{{ $t('next') }}
        <b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_enquiry"
        ></b-img>
      </b-button>
    </div>
    <div v-if="$store.getters.currentEnquiry == 2">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="BackToDrMessageForm()"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <div class="text-center">
        <b-img :src="path + enquiry_ico" alt="enquiry"></b-img>
        <p class="text-22 semi-bold mt-2 mb-0">
          {{ title }}
        </p>
      </div>
      <hr />
      <div class="terms">
        <div v-html="terms"></div>
        <p class="date">{{ new Date() | moment2 }}</p>
      </div>
      <div class="text-center mt-2">
        <b-form-checkbox v-model="checked">{{ $t('agree') }}</b-form-checkbox>
      </div>
      <b-button
        block
        size="lg"
        :disabled="disabledTerms"
        class="mt-2"
        v-on:click="serviceEnquiry(3)"
        >{{ $t('next')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_enquiry"
        ></b-img>
      </b-button>
    </div>
    <div v-if="$store.getters.currentEnquiry == 3" class="text-center">
      <b-img :src="path + enquiry_ico" alt="enquiry"></b-img>
      <p class="text-22 semi-bold mt-2">{{ title }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceEnquiry(2)"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="mb-3 info"
      >
        <p class="text-primary text-14 semi-bold mb-0">
          {{ $t('send_enquiry_request') }} {{ btntitle }}
        </p>
        <p class="text-primary text-14 semi-bold mb-0">
          <span class="bold">{{ name }}, {{ inr }}</span>
        </p>
      </b-card>
      <p class="text-14 semi-bold">
        {{ $t('enquiry_sms') }}
      </p>
      <Sms></Sms>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_enquiry') }}
      </p>
      <p class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>
    <div v-if="$store.getters.currentEnquiry == 4" class="text-center">
      <b-img :src="path + enquiry_ico" alt="enquiry"></b-img>
      <p class="text-22 semi-bold mt-2">{{ title }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
        <p class="text-22 mb-0">
          {{ $t('order') }}
          <span
            v-if="$store.getters.orderStatus.order_status == 'confirmed'"
            class="bold"
            >{{ $t('confirmed') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'paid'"
            class="bold"
            >{{ $t('paid') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'payment_failed'"
            class="bold"
            >{{ $t('payment_failed') }}</span
          >
        </p>
        <p class="text-14 mb-0">
          {{ $t('request_enquiry') }}
        </p>
        <p class="text-14 mb-0">
          {{ $t('price') }}&nbsp;{{ $store.getters.orderStatus.order_price }}
        </p>
      </b-card>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_enquiry_sms') }}
      </p>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img>
      </b-button>
    </div>
    <div v-if="$store.getters.currentEnquiry == 5">
      <WaitOrder :title="title" :subtitle="''" :image="enquiry_ico" />
    </div>
    <div v-if="$store.getters.currentEnquiry == 6">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="title" :subtitle="''" :image="enquiry_ico" /></div>
  </div>
  </b-card>
</template>

<script>
import axios from 'axios'
import config from '../../../config'
import close from '../../assets/old/close.svg'
import current from '../../assets/old/current.svg'
import angle_down from '../../assets/old/angle_down.svg'
import angle_right from '../../assets/old/angle_right.svg'
import angle_right_small from '../../assets/old/angle_right_small.svg'
import angle_left_small from '../../assets/old/angle_left_small.svg'
import angle_right_blue from '../../assets/old/angle_right_blue.svg'
import arrow_left from '../../assets/old/arrow_left.svg'
import arrow_right from '../../assets/old/arrow_right.svg'
import info from '../../assets/old/info.svg'
// import enquiry from "../../assets/old/enquiry.svg";
import enquiry from '../../assets/icons/enquiry2.svg'
// import btn_ico from "../../assets/old/enquiry_white.svg";
import btn_ico from '../../assets/icons/enquiry.svg'
import gray from '../../assets/old/gray.svg'
import green from '../../assets/old/green.svg'
import red from '../../assets/old/red.svg'

import Sms from '../services/Sms'
let moment = require('moment')
import Multiselect from 'vue-multiselect'
import Vue from 'vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import VueNativeSock from 'vue-native-websocket'
import { CheckApiUrl } from '../../modules/CheckApiUrlModule';
import Preloader from '../Preloader.vue'

import WaitOrder from '../partials/WaitOrder.vue'
import FailedOrder from '../partials/FailedOrder.vue'

Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
})
export default {
  components: {
    Sms,
    Multiselect,
    Preloader,
    WaitOrder,
    FailedOrder
  },
  data() {
    return {
      path: config.URL,
      close: close,
      current: current,
      angle_right: angle_right,
      angle_right_blue: angle_right_blue,
      angle_right_small: angle_right_small,
      angle_left_small: angle_left_small,
      arrow_left: arrow_left,
      arrow_right: arrow_right,
      angle_down: angle_down,
      info: info,
      enquiry_ico: enquiry,
      btn_ico: btn_ico,
      gray: gray,
      green: green,
      red: red,
      id: '',
      title: '',
      btntitle: '',
      texts: [],
      enquiry: null,
      count: null,
      nameEnquiry: '',
      questions: [],
      answers: {},
      selected: 'Zvoľte ak sa Vás to týka',
      selectStyle: {
        background:
          '#fff url("' +
          config.URL +
          angle_down +
          '") no-repeat right 0.75rem top 14px/18px 10px',
      },
      name: '',
      inr: '',
      checked: false,
      terms: null,
      error: false,
      show_description: false,
      show_spinner: true,
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    inr: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(512),
    },
  },
  computed: {
    disabled() {
      return this.name.length > 2 &&
        this.name.length <= 512 &&
        this.inr.replace(/\D/g, '').length > 8 &&
        this.inr.replace(/\D/g, '').length <= 12
        ? false
        : true
    },
    disabledTerms() {
      return this.checked ? false : true
    },
    nameMinLength() {
      return this.name.length > 2 ? false : true
    },
    nameMaxLength() {
      return this.name.length <= 512 ? false : true
    },
    inrMinLength() {
      return this.inr.replace(/\D/g, '').length > 8 ? false : true
    },
    inrMaxLength() {
      return this.inr.replace(/\D/g, '').length <= 12 ? false : true
    },
  },
  updated() {
    // this.$raven.captureBreadcrumb({category: "updated hook", level: 'warning'});
    this.$store.dispatch('checkBack')
  },
  mounted() {
    this.getEnquiries()
    this.answers = { q0: moment(new Date()).format('DD.MM.YYYY, H:mm:ss') }
  },
  methods: {
    collapse() {
      event.target.parentElement.classList.toggle('collapsed')
    },
    getEnquiries() {

      let url= CheckApiUrl.check('/enquiry_v2/' + this.$store.getters.name)

      axios
        .get(url)
        .then(response => {
          // this.$raven.captureBreadcrumb({category: "get enquiry data", data: [response.data, response], level: 'warning'});

          this.$store.commit('setEnquiry', response.data)
          this.count = Object.keys(this.$store.getters.enquiry.enquiries).length
          if (this.count == 1) {
            this.serviceEnquiry(
              1,
              Object.keys(this.$store.getters.enquiry.enquiries)
            )
          }

          if (this.$route.params.enquirydetail != undefined) {
            /* Detail */
            this.serviceEnquiry(1, this.$route.params.enquirydetail)
          } else {
            this.$store.dispatch('setCurrentEnquiry', 0)
          }
          /* Remove parameter from enquryties object array */
          config.DEPRECATED_ENQUERIES.forEach(value => {
            delete response.data.enquiries[value]
          })
          this.$store.commit('setEnquiry', response.data)
          this.show_spinner = false;
        })
    },
    getTerms() {
      axios
        .get(config.API + '/enquiryconsent/' + this.nameEnquiry)
        .then(response => {
          this.terms = response.data
        })
    },
    closeTab() {
      this.$router.push({ name: 'home' })
      // this.$store.dispatch("setCurrentService", 0);
      this.$store.dispatch('setCurrentEnquiry', 0)
    },
    serviceEnquiry(index, key) {
      if (index == 1) {
        this.enquiry = Object.entries(
          this.$store.getters.enquiry.enquiries
        ).filter(val => val[0] == key)

        // this.$raven.captureBreadcrumb({category: "parse enquiry data", data: [this.enquiry, key], level: 'warning'});

        this.nameEnquiry = this.enquiry[0][0]
        this.title = this.enquiry[0][1].title
        this.btntitle = this.enquiry[0][1].btntitle
        this.texts = this.enquiry[0][1].text
        this.questions = this.enquiry[0][1].questions
      }
      if (index == 2) {
        this.$v.$touch()
        let top = document.getElementById('btn-back')
        top.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        if (this.$v.$invalid || this.inrMinLength || this.inrMaxLength) {
          this.error = true
          this.$nextTick(() => {
            let element = document.getElementsByClassName('submiterror')
            element[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
          return false
        } else {
          this.error = false
          this.getTerms()
          this.$store.dispatch('setCurrentEnquiry', index)
        }
      }
      if (index == 3) {
        let answers = []
        Object.entries(this.answers).map(val => {
          answers.push({
            id: val[0],
            answer: val[1],
          })
        })
        let answerA, answerB
        answers.sort((a, b) => {
          answerA =
            a.id == 'q0'
              ? -1
              : this.questions.filter(val => val.id == a.id)[0].order
          answerB =
            b.id == 'q0'
              ? -1
              : this.questions.filter(val => val.id == b.id)[0].order
          if (answerA < answerB) {
            return -1
          }
          if (answerA > answerB) {
            return 1
          }
        })
        const enquiry_name = {}
        enquiry_name[this.nameEnquiry] = answers
        this.$store.dispatch('placeOrder', {
          type: 'enquiry',
          name: this.name,
          pnr: this.inr,
          consent: true,
          answers: enquiry_name,
        })
        this.$store.dispatch('setCurrentEnquiry', index)
        setTimeout(() => {
          this.$connect(
            config.SOCKET + this.$store.getters.placeOrder.order_key,
            {
              format: 'json',
            }
          )
          this.$socket.onmessage = data => {
            if (JSON.parse(data.data).event == 'order_confirmed') {
              //this.$store.dispatch('getOrderStatus')
              this.$store.dispatch('setCurrentEnquiry', 5)
            } else if (JSON.parse(data.data).event == 'order_paid') {
              this.$store.dispatch('setCurrentEnquiry', 5)
            } else if (JSON.parse(data.data).event == 'order_ready') {
              this.$store.dispatch('getOrderStatus')
              this.$store.dispatch('setCurrentEnquiry', 4)
              this.$disconnect()
            } else if (JSON.parse(data.data).event == 'order_payment_failed') {
              this.$store.dispatch('setCurrentEnquiry', 6)
              this.$disconnect()
            } else {
              // console.log('error', JSON.parse(data.data).event)
            }
          }
        }, 5000)
      } else {
        this.$store.dispatch('setCurrentEnquiry', index)
      }
    },
    BackToDrMessageForm(){
      if(this.$route.params.enquirydetail != undefined) { 
      this.$store.dispatch('setCurrentEnquiry', 1)
      } else{ this.serviceEnquiry(0) }
    },
    ButtonServiceEnquiry(index){
      this.serviceEnquiry(1, index)

      this.$router.push({
        name: 'dotazniky_detail',
        params: { enquirydetail: index },
      })
    },
    ButtonReturnToServices(){
      this.serviceEnquiry(0)
      this.$router.push({
        name: 'enquiry'
      })
    }
  },
  filters: {
    moment: date => {
      return moment(date).format('D.M.YYYY, H:mm:ss')
    },
    moment2: date => {
      return moment(date).format('D.M.YYYY')
    },
  },
}
</script>

<style scoped lang="scss">
.terms {
  height: calc(100vh - 500px);
  overflow: scroll;

  .date {
    margin-top: -1em !important;
  }
}
.service {
  background-color: #22376c;
  border-radius: 0.25rem;

  &.disabled {
    background-color: #bdbdbd;
    pointer-events: none;
  }
}
.position-right {
  margin: 0 0 0 auto;
}
li {
  figure {
    min-width: 65px;
    height: 60px;
  }
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: rgba(34, 55, 108, 0.9);
  }

  &.enquiry:hover {
    background-color: rgba(255, 71, 71, 0.9);
  }

  & > img {
    height: 25px;
  }
}
.wrapper-collapse {
  display: block;
  width: 100%;
  padding: 0.475rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  a:hover {
    text-decoration: none !important;
  }

  &.collapsed {
    background-color: #eef7fc !important;
  }
}
.question-image {
  max-width: 100%;
}
@media (max-width: 575.98px) {
  .terms {
    height: calc(100vh - 400px);
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
